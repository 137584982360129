import React, { useState } from 'react'
import { Box, Checkbox, Label } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import shortid from 'shortid'
export default function TagsFilter({
  variant,
  setArticleFilterSettings,
  articleFilterSettings,
  value,
  tags,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Box variant={`${variant}.filterTagContainer`}>
      <Label variant={`${variant}.filterLabel`}>Filter By Tags</Label>
      <Box
        variant={`${variant}.filterTagDropdown`}
        onClick={() => {
          setDropdownOpen(!dropdownOpen)
        }}
      >
        {`${value.length} Tags Selected`}
        <FontAwesomeIcon icon={faCaretDown} />
      </Box>
      {dropdownOpen && (
        <Box
          key={shortid.generate()}
          variant={`${variant}.filterTagList`}
          className='filterTagList'
        >
          {tags?.map((tag) => {
            const isChecked = value.includes(tag)
            return (
              <Label
                key={tag}
                variant={`${variant}.filterTagLabel`}
                className='filterTagLabel'
              >
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      // remove tag from checked list
                      const newCheckedtags = value.filter((selectedTag) => {
                        return selectedTag.toLowerCase() !== tag.toLowerCase()
                      })
                      setArticleFilterSettings({
                        ...articleFilterSettings,
                        tags: newCheckedtags,
                      })
                    } else {
                      // add tag to checked list
                      setArticleFilterSettings({
                        ...articleFilterSettings,
                        tags: [...articleFilterSettings.tags, tag],
                      })
                    }
                  }}
                />
                {tag}
              </Label>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
